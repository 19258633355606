<template>
	<settings-container window="100%">
		<div class="subscription-information">
			<div class="information-wrapper" v-if="subscription_details !== null">
				<div class="tier-header">
					<p>Aktueller Tarif</p>
				</div>
				<div class="price-header">
					<p>Aktueller Preis</p>
				</div>
				<div class="accounts-header">
					<p>
						Verfügbare
						{{
							$store.getters.getAppMode === 'doctor' ? 'Labor' : 'Ärzte'
						}}-Accounts
					</p>
				</div>
				<div class="payment-header">
					<p>Zahlungsart</p>
				</div>
				<div class="tier-header">
					<p>{{ subscription_details.tier.name }}</p>
				</div>
				<div class="price-header">
					<p>
						{{ subscription_details.price_per_period | toEur }} /
						{{
							subscription_details.accounting_period === '1' ? 'Monat' : 'Jahr'
						}}
					</p>
					<p><small>zzgl. der gesetzlichen Mehrwertsteuer</small></p>
				</div>
				<div class="accounts-header">
					<p>
						{{
							`${subscription_details.current_users} / ${subscription_details.users}`
						}}
					</p>
				</div>
				<div class="payment-header">
					<p>Rechnung</p>
				</div>
			</div>

			<div class="update-subscription-wrapper">
				<button
					clasS="button text-button"
					@click="show_subscription_changes = !show_subscription_changes"
				>
					{{
						!show_subscription_changes ? 'Änderungen vornehmen' : 'Schließen'
					}}
				</button>
				<div class="update-subscription" v-if="show_subscription_changes">
					<div class="update-assoc-count">
						<h3>
							Mehr
							{{ $store.getters.getAppMode === 'doctor' ? 'Labore' : 'Ärzte' }}
							hinzufügen
						</h3>
						<div class="number-input">
							<button
								class="minus"
								@click.prevent="new_assoc_count > 0 ? new_assoc_count-- : null"
							></button>
							<input
								class="quantity"
								:min="0"
								name="quantity"
								type="number"
								v-model="new_assoc_count"
							/>
							<button class="plus" @click.prevent="new_assoc_count++"></button>
						</div>
						<div class="new-pricing" v-if="new_assoc_count > 0">
							<p>
								Gesamtanzahl
								{{
									$store.getters.getAppMode === 'doctor' ? 'Labor' : 'Ärzte'
								}}:
								{{ new_assoc_count + subscription_details.users }}
							</p>
							<p v-if="new_assoc_count > 0">
								Neuer Preis
								<strong>{{ new_price_add_assocs | toEur }}</strong> pro
								{{
									subscription_details.accounting_period === '1'
										? 'Monat'
										: 'Jahr'
								}}
								<br />
								<small>zzgl. der gesetzlichen Mehrwertsteuer</small>
							</p>
						</div>
						<button
							type="submit"
							class="w-button button"
							@click="updateAssocCount"
							:disabled="assoc_updated"
							v-if="new_assoc_count > 0"
						>
							Kostenpflichtig ändern
						</button>
						<div class="result">
							<p class="is-success" v-if="assoc_updated && !assoc_update_error">
								Ihr Account wurde erfolgreich erweitert
							</p>
							<p class="has-error" v-if="assoc_updated && assoc_update_error">
								Es gab ein Fehler. Bitte versuchen Sie es erneut, oder wenden
								Sie sich an den Support
							</p>
						</div>
					</div>
					<div class="update-subscription-details">
						<h3>Tarif ändern</h3>
						<div class="select-subscription-wrapper">
							<div class="select-input">
								<label for="tier">Tarif</label>
								<select id="tier" v-model="new_subscription_settings.tier">
									<option
										v-for="option in upgradeOptions"
										:key="option.UUID"
										:value="option.UUID"
										:disabled="
											option.users < subscription_details.users &&
											option.users !== -1
										"
									>
										{{ option.name }}
									</option>
								</select>
							</div>
							<div class="select-input">
								<label for="accounting_period">Abrechnungsperiode</label>
								<select
									id="accounting_period"
									v-model="new_subscription_settings.accounting_period"
								>
									<option value="1">Monatlich</option>
									<option value="12">Jährlich</option>
								</select>
							</div>
						</div>
						<div
							class="new-pricing"
							v-if="
								new_subscription_settings.tier !==
									subscription_details.tier.UUID ||
								new_subscription_settings.accounting_period !==
									subscription_details.accounting_period
							"
						>
							<p
								v-if="
									new_subscription_settings.tier !==
									upgradeOptions.find((o) => o.users === -1).UUID
								"
							>
								Neuer Preis:
								<strong>{{ new_rate | toEur }}</strong> pro
								{{
									new_subscription_settings.accounting_period === '1'
										? 'Monat'
										: 'Jahr'
								}}
								<br />
								<small>zzgl. der gesetzlichen Mehrwertsteuer</small>
							</p>
							<p v-else>Neuer Preis: Auf Anfrage</p>
							<a
								class="w-button button"
								:href="`mailto:support@labload.de?subject=Abonnement%20Aktualisierung&body=Hallo%2C%0D%0A%0D%0Aich%20m%C3%B6chte%20mein%20Abonnement%20gerne%20%C3%A4ndern%20zu%3A%0D%0ATarif%3A%20Unlimited%0D%0AAbrechnungsperiode%3A%20${
									new_subscription_settings.accounting_period === '1'
										? 'Monat'
										: 'Jahr'
								}`"
								v-if="
									new_subscription_settings.tier ===
									upgradeOptions.find((o) => o.users === -1).UUID
								"
								>Anfrage stellen</a
							>
							<button
								type="submit"
								class="w-button button"
								v-else
								@click="update_subscription"
								:disabled="subscription_updated"
							>
								Kostenpflichtig ändern
							</button>
						</div>
						<div class="result">
							<p
								class="is-success"
								v-if="subscription_updated && !subscription_update_error"
							>
								Ihr Account wurde erfolgreich geändert
							</p>
							<p
								class="has-error"
								v-if="subscription_updated && subscription_update_error"
							>
								Es gab ein Fehler. Bitte versuchen Sie es erneut, oder wenden
								Sie sich an den Support
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</settings-container>
</template>

<script>
import formatMixin from '@/mixins/format.mixin';
export default {
	components: {
		settingsContainer: () =>
			import('@/components/layout/settings-container.vue')
	},
	mixins: [formatMixin],
	data() {
		return {
			subscription_details: null,
			show_subscription_changes: false,
			new_assoc_count: 0,
			upgrade_options: null,
			new_subscription_settings: {
				tier: '',
				accounting_period: ''
			},
			assoc_updated: false,
			subscription_updated: false,
			assoc_update_error: false,
			subscription_update_error: false
		};
	},
	computed: {
		new_price_add_assocs() {
			const basePrice = this.upgradeOptions.find(
				(u) => u.name === this.subscription_details.tier.name
			)[
				this.subscription_details.accounting_period === '1'
					? 'price_monthly'
					: 'price_yearly'
			];
			const totalUsers = this.new_assoc_count + this.subscription_details.users;
			const included_users = this.upgradeOptions.find(
				(u) => u.name === this.subscription_details.tier.name
			)['users'];
			const extra_user_cost = this.upgradeOptions.find(
				(u) => u.name === this.subscription_details.tier.name
			)['cost_per_user'];
			const userPrice =
				totalUsers > included_users
					? (totalUsers - included_users) *
					  (extra_user_cost *
							(this.subscription_details.accounting_period === '1' ? 1 : 10.8))
					: 0;
			return basePrice + userPrice;
		},
		new_rate() {
			const price = this.upgradeOptions.find(
				(u) => u.UUID === this.new_subscription_settings.tier
			)[
				this.new_subscription_settings.accounting_period === '1'
					? 'price_monthly'
					: 'price_yearly'
			];
			return price;
		}
	},
	mounted() {
		this.get_subscription_details();
	},
	methods: {
		async get_subscription_details() {
			const subscription = await this.$api.post(
				'/account/get_subscription_details',
				{
					account_id: this.$store.getters.getAccount.account_id
				},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			this.resetAll();
			this.subscription_details = subscription.data;
			this.new_subscription_settings.tier = subscription.data.tier.UUID;
			this.new_subscription_settings.accounting_period =
				subscription.data.accounting_period;
			this.getUpgradeOptions();
		},
		async getUpgradeOptions() {
			const optionsResponse = await this.$api.post(
				'/payment/get_upgrade_options',
				{},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			this.upgradeOptions = optionsResponse.data;
		},
		resetAll() {
			this.show_subscription_changes = false;
			this.new_subscription_settings = {
				tier: '',
				accounting_period: ''
			};
			this.new_assoc_count = 0;
			this.assoc_updated = false;
			this.subscription_updated = false;
			this.assoc_update_error = false;
			this.subscription_update_error = false;
		},
		async updateAssocCount() {
			const updateReponse = await this.$api.post(
				'/account/update_assoc_count',
				{
					account_id: this.$store.getters.getAccount.account_id,
					new_assoc_count: this.new_assoc_count,
					new_price: this.new_price_add_assocs
				},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			this.assoc_updated = true;
			this.assoc_update_error = updateReponse.status !== 200;
			await new Promise((resolve) => setTimeout(resolve, 1500));
			this.get_subscription_details();
		},
		async update_subscription() {
			const updateReponse = await this.$api.post(
				'/account/update_subscription',
				{
					account_id: this.$store.getters.getAccount.account_id,
					...this.new_subscription_settings
				},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			this.subscription_updated = true;
			this.subscription_update_error = updateReponse.status !== 200;
			await new Promise((resolve) => setTimeout(resolve, 1500));
			this.get_subscription_details();
		}
	}
};
</script>

<style lang="scss">
.subscription-information {
	width: 100%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	align-items: center;
	grid-template-rows: auto 1fr;
	.information-wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, 1fr);
		box-shadow: inset 0 0 12px -3px #b6b6b6;
		div {
			padding: 2rem 0;
			display: flex;
			justify-content: center;
			align-items: center;
			justify-items: center;
			border-style: solid;
			border-color: #d3d3d3;
			border-width: 0 1px 1px 1px;
			p {
				font-size: 0.85rem;
				text-align: center;
			}
			&.price-header {
				flex-direction: column;
				padding: 1rem 0;
			}
		}
	}
	.update-subscription-wrapper {
		text-align: center;
		.button {
			margin: 0.85rem;
		}
		.update-subscription {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr;
			.update-assoc-count,
			.update-subscription-details {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr minmax(70px, 1fr) minmax(115px, 2fr) auto;
				justify-items: center;
				justify-content: center;
				align-items: center;
				gap: 0.15rem;
				h3 {
					grid-column: 1 /3;
					grid-row: 1;
				}
				.number-input {
					display: inline-flex;
					margin: 0.5rem 0;
					border-radius: 3px;
					grid-column: 1;
					grid-row: 2;
					width: 100%;
					button {
						outline: none;
						background-color: transparent;
						align-items: center;
						justify-content: center;
						width: 2rem;
						height: 2rem;
						cursor: pointer;
						border: 1px solid transparentize($text-color, $amount: 0.75);
						border-radius: 5px 0 0 5px;
						&:before,
						&:after {
							display: inline-block;
							position: absolute;
							content: '';
							width: 0.5rem;
							height: 2px;
							background-color: $text-color;
							transform: translate(-50%, -50%);
						}
						&.plus {
							border-radius: 0 5px 5px 0;
							&:after {
								transform: translate(-50%, -50%) rotate(90deg);
							}
						}
					}
					input[type='number'] {
						border: 0px solid transparentize($text-color, $amount: 0.75);
						border-width: 1px 0 1px 0;
						max-width: 50%;
						border-radius: 0;
						text-align: center;
						font-weight: bold;
						-webkit-appearance: textfield;
						-moz-appearance: textfield;
						appearance: textfield;
						&::-webkit-inner-spin-button,
						&::-webkit-outer-spin-button {
							-webkit-appearance: none;
						}
					}
				}
				.new-pricing {
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
				}
				.button {
					grid-column: 1 /3;
					margin-left: 0;
					justify-self: flex-start;
				}
				.result {
					grid-column: 1 / 3;
					grid-row: 4;
					display: flex;
					justify-content: flex-start;
					width: 100%;
				}
				.select-subscription-wrapper {
					grid-column: 1 / 3;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: flex-start;
					gap: 1rem;
					.select-input {
						width: 100%;
						justify-content: flex-start;
						align-items: flex-start;
						flex-direction: column;
						display: flex;
						select {
							width: 90%;
						}
					}
				}
			}
		}
	}
}
</style>
